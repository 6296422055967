import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <>
    <footer>
      <div className="container">
        <div className="_inner">
          <div className="columns">
            <div className="column _links">
              <span>© {new Date().getFullYear()} IMMUNO NATURA</span>
              <Link to="/bs/impresum" title="IMPRESUM">
                Impresum
              </Link>
              <Link
                to="/bs/zastita-podataka"
                title="IZJAVA O ZAŠTITI LIČNIH PODATAKA"
              >
                Zaštita podataka
              </Link>
              <Link to="/bs/kontakt" title="KONTAKTIRAJTE NAS!">
                Kontakt
              </Link>
            </div>
            <div className="column has-text-right">
              <a href="#">Created by Well Business Promotion</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <nav class="menuShare">
      <input
        type="checkbox"
        href="#"
        class="menu-open"
        name="menu-open"
        id="menu-open"
      />
      <label class="menu-open-button" for="menu-open">
        <i class="fas fa-share-alt share-icon"></i>
      </label>
      <a
        class="menu-item facebook_share_btn"
        href="#"
        data-open-share="facebook"
        data-open-share-link="#"
      >
        <i class="fab fa-facebook-f" aria-hidden="true"></i>
      </a>

      <a
        class="menu-item instagram_share_btn"
        href="#"
        data-open-share="instagram"
        data-open-share-url="#"
      >
        <i class="fab fa-instagram" aria-hidden="true"></i>
      </a>

      <a
        class="menu-item linkedin_share_btn"
        href="#"
        data-open-share="linkedin"
        data-open-share-url="#"
      >
        <i class="fab fa-linkedin-in" aria-hidden="true"></i>
      </a>
    </nav>
  </>
)

export default Footer
